#main {
    width: 100%;
    /*height: 100vh;*/
    margin: 0;
    padding: 0;
    background-image: url("/src/asset/img/profile_back.jpg");
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#background-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, .4);
    padding: 30px 30px 5px;
    border-radius: 20px;
    margin: 20px;
    max-width: 400px;
}

#avatar {
    height: 15rem;
    border-radius: 50%;
    border: 2px dashed white;
}

#name {
    font-size: 2rem;
}

#intro {
    color: #fafafa;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 0;
    text-align: center;
}

#dividing-line {
    color: #e6e6e6;
}

#social-links {
    padding-left: 0;
}

.social-link {
    list-style: none;
    display: inline-block;
    margin: 0 10px;
}

.social-link-icon {
    height: 2rem;
}